<template>
<div class="div_principal">
    <div class="topo">
        <img src="/images/logo.png" id="logo"/>
        <p>Preencha as informações a seguir para criar a sua conta</p>
    </div>
    <div class="container">
        <p class="titulo">Da Empresa</p>
        <div class="grid">
            <div class="col-12">
                <div class="inputContainer">
                    <label>Nome Fantasia</label>
                    <input type="text" class="input" v-model="nome_fantasia_empresa" />
                </div>
            </div>
            <div class="col-6">
                <div class="inputContainer">
                    <label>CNPJ</label>
                    <InputMask type="text" class="input" v-model="cnpj_empresa" mask="99.999.999/9999-99" />
                </div>
            </div>
            <div class="col-6">
                <div class="inputContainer">
                    <label>Razão Social</label>
                    <input type="text" class="input" v-model="razao_social_empresa" />
                </div>
            </div>
            <div class="col-6">
                <div class="inputContainer">
                    <label>E-mail</label>
                    <input type="text" class="input" v-model="email_empresa" />
                </div>
            </div>
            <div class="col-6">
                <div class="inputContainer">
                    <label>Telefone</label>
                    <InputMask type="text" class="input" v-model="telefone_empresa" mask="(99)9999-9999?9"/>
                </div>
            </div>

            <div class="col-12">
                <div class="inputContainer">
                    <label>Atividade</label>
                <select class="input" v-model="atividade_empresa">
                    <option v-for="(area, index) in areas_atuacao" :key="index" :value="area.id">{{area.nome}}</option>
                </select>
                </div>
            </div>
            <div class="col-6">
                <div class="inputContainer">
                    <label>CEP</label>
                    <InputMask type="text" class="input" v-model="cep_empresa" mask="99.999-999" @blur="carregarEnderecoEmpresa($event)"/>
                </div>
            </div>
            <div class="col-8">
                <div class="inputContainer">
                    <label>Endereço</label>
                    <input type="text" class="input" v-model="endereco_empresa"/>
                </div>
            </div>
            <div class="col-4">
                <div class="inputContainer">
                    <label>Número</label>
                    <InputMask type="text" class="input" v-model="numero_empresa" mask="?99999" />
                </div>
            </div>
            <div class="col-6">
                <div class="inputContainer">
                    <label>Bairro</label>
                    <input type="text" class="input" v-model="bairro_empresa"/>
                </div>
            </div>

        </div>
        <p class="titulo">Do representante legal</p>

        <div class="grid">
            <div class="col-12">
                <div class="inputContainer">
                    <label>Nome</label>
                    <input type="text" class="input" v-model="nome_representante"/>
                </div>
            </div>

            <div class="col-12">
                <div class="inputContainer">
                    <label>E-mail</label>
                    <input type="text" class="input" v-model="email_representante"/>
                </div>
            </div>
            <div class="col-6">
                <div class="inputContainer">
                    <label>Senha</label>
                    <input type="password" class="input" v-model="senha"/>
                </div>
            </div>
            <div class="col-6">
                <div class="inputContainer">
                    <label>Senha</label>
                    <input type="password" class="input" v-model="confirma_senha"/>
                </div>
            </div>

            <div class="col-6">
                <div class="inputContainer">
                    <label>CEP</label>
                    <InputMask type="text" class="input" v-model="cep_representante" mask="99.999-999" @blur="carregarEnderecoRepresentante($event)"/>
                </div>
            </div>

            <div class="col-8">
                <div class="inputContainer">
                    <label>Endereço Residencial</label>
                    <input type="text" class="input" v-model="endereco_representante"/>
                </div>
            </div>
            <div class="col-4">
                <div class="inputContainer">
                    <label>Número</label>
                    <InputMask type="text" class="input" v-model="numero_representante" mask="?99999" />
                </div>
            </div>

            <div class="col-6">
                <div class="inputContainer">
                    <label>Bairro</label>
                    <input type="text" class="input" v-model="bairro_representante"/>
                </div>
            </div>
            

            <div class="col-6">
                <div class="inputContainer">
                    <label>CPF</label>
                    <InputMask type="text" class="input" v-model="cpf_representante" mask="999.999.999-99" />
                </div>
            </div>
            <div class="col-6">
                <div class="inputContainer">
                    <label>Celular</label>
                    <InputMask type="text" class="input" v-model="celular_representante" mask="(99)99999-999?9"/>
                </div>
            </div>
        </div>
        <div class="div_btn">
            <p id="informacoes_bottom">Ao criar sua conta você concordará com os Termos de Uso e Política de Privacidade da plataforma.</p>
            <button type="button" class="btn_entrar" @click="cadastrar()">Cadastrar</button>
        </div>
        
    </div>
</div>
</template>

<script>
import Api from '../service/api';
import InputMask from 'primevue/inputmask';
export default {
    mounted(){
        document.body.classList.add('div_principal')
        this.api = new Api();
        this.listarAreasAtuacao()
    },
    data() {
        return {
            InputMask,
            areas_atuacao:[],
            nome_fantasia_empresa:'',
            cnpj_empresa:'',
            email_empresa:'',
            razao_social_empresa:'',
            telefone_empresa:'',
            atividade_empresa:'',
            endereco_empresa:'',
            numero_empresa:'',
            bairro_empresa:'',
            cep_empresa:'',
            nome_representante:'',
            endereco_representante:'',
            numero_representante:'',
            bairro_representante:'',
            cep_representante:'',
            cpf_representante:'',
            celular_representante:'',
            email_representante:'',
            senha:'',
            confirma_senha:'',
        }
    },
    methods: {
        listarAreasAtuacao() {
            this.loading = true;
            this.api.listarAreasAtuacao().then(data => {
                this.areas_atuacao = data.data
            }); 
        },
        carregarEnderecoEmpresa(event) {
            var cep = event.target.value.replace(".", "").replace("-", "");
            this.api.carregaCep(cep).then(data => {
                this.endereco_empresa = data.data.logradouro;
                this.bairro_empresa = data.data.bairro
            });    
        },
        carregarEnderecoRepresentante(event) {
            var cep = event.target.value.replace(".", "").replace("-", "");
            this.api.carregaCep(cep).then(data => {
                this.endereco_representante = data.data.logradouro;
                this.bairro_representante = data.data.bairro
            });
        },
        cadastrar() {
            this.api.cadastrarRepresentante(this.nome_representante,this.email_representante,this.endereco_representante,this.numero_representante,this.bairro_representante,this.cep_representante,this.cpf_representante,this.celular_representante,this.senha).then(data =>{
                if(data.data.erro == false){
                    alert('Erro ao cadastrar Representante.')
                    return;
                }
               // this.$storage.setStorageSync("jwt", data.data.token);
                this.api.cadastrarEmpresa(data.data.id,this.nome_fantasia_empresa, this.cnpj_empresa,this.email_empresa,this.razao_social_empresa,this.telefone_empresa,this.atividade_empresa,this.endereco_empresa,this.numero_empresa,this.bairro_empresa,this.cep_empresa).then(data2 =>{

                if (data2.data.verifica_erro == true) {
                    alert('Erro ao cadastrar Empresa.')
                    return;
                }
    
                this.$storage.setStorageSync("jwt", data2.data.token);
                this.$storage.setStorageSync("empresa", data2.data.empresas.empresas[0])
                this.$storage.setStorageSync("nome", data2.data.user.name)
                this.$storage.setStorageSync("user", data2.data.user)

                this.$router.push({ name: 'dashboard' }) 

                })
            })
            
        },
    },
}
</script>

<style scoped>
    .topo{
        width: 100%;
        text-align: center;

    }
    .topo p{
        font-size:14pt;
    }
    #logo{
        width:200px;
    }
    .container{
        margin-top:30px;
        width:720px;
        margin-left: auto;
        margin-right: auto;
    }
    .titulo{
        font-size: 12pt;
        font-weight: bold;
        text-transform: uppercase;
    }
    .input{
        width:100%;
        border: #EFEFEF solid 1px;
        background-color:#FBFBFB;
        padding:15px;
        font-size: 11pt;
        margin-bottom:5px;
        margin-top:5px;
    }
    .div_principal{
        margin-top:0;
        padding-top:50px;
        padding-bottom:50px
    }
    .div_btn{
        width:100%;
        text-align: center;
    }
    .btn_entrar{
        cursor: pointer;
        width: 200px;
        background-color:#4B89EE;
        color:#fff;
        padding:12px;
        border:none;
        border-radius:5px;
        font-size: 12pt;
        font-weight: bold;
        text-transform: uppercase;
    }
    #informacoes_bottom{
        margin-top:15px;
    }
</style>